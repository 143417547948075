<template>
  <div class="HomeHeader">
    <PublicHeader
      :is-show-logo="true"
      :is-show-search="false"
      icon-type=""
      class="Header"
      :nav-type="COMMON.HEADER.NAV.TYPE.HOME_HEADER"
      @topTop="refresh"

      :picList="picList"
      :festivalList="festivalList"
      :solarTermsList="solarTermsList"
      :recommendList="recommendList"
      :threeDCategoryList="threeDCategoryList"
      :threeDRecommendList="threeDRecommendList"
      :festivalData="festivalData"
      :styleCategoryList="styleCategoryList"
      :styleRecommendList="styleRecommendList"
      :isFixedHeader="false"
    />
  </div>
</template>

<script>
import {
  PublicHeader
} from "@/components/common/index.js";
import {COMMON} from "@/constant/COMMON";


export default {
  name: "HomeHeader",
  components: {
    PublicHeader,
  },
  props:{
    picList:[],
    festivalList:[],
    solarTermsList:[],
    recommendList:[],
    threeDCategoryList: [],
    threeDRecommendList: [],
    festivalData:{},
    designStyle:{},
    styleCategoryList:[],
    styleRecommendList:[]
  },
  data() {
    return {
      COMMON,
      hotData:{
        name: '1111'
      }
    }
  },
  mounted() {
    this.$store.commit('layout/SET_IS_SHOW_SEARCH', false)
  },
  methods: {
    refresh() {
      window.location.reload();
    },
  }
}
</script>

<style lang="scss" scoped>
//@import "pages/home";

.Header {
  padding: 30px 0;

  /deep/ .icon-logo {
    color: #FFFFFF !important;
  }
}
</style>
