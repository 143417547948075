export const head = {
  title: '潮国创意_原创3d图片素材电商设计海报_免费正版商用素材库',

  meta: [
    {
      hid: 'description',
      name: 'description',
      content: '潮国创意是专为企业和个人提供正版商用3d图片素材、电商设计海报、节日节气模板、立体背景、国潮风格等创意设计网站;找3d图片素材、电商设计海报、节日节气模板、立体背景等国潮创意图片素材,潮国创意chaopx.com值得信懒。'
    },
    {
      hid: 'keywords',
      name: 'keywords',
      content: '潮国创意,3d素材,图片素材,海报模板,设计素材,电商设计,节日海报,UI设计,立体背景,印刷物料'
    }

  ]
}
