<template>
  <div id="app" class="container">
    <div ref="HeaderWrap" class="header_wrap">
      <!-- 首页导航 -->
      <HomeHeader/>

      <!-- 背景路径 -->
      <BackgroundPath/>

      <!-- 潮流设计主题 -->
      <TrendDesign
        :search-placeholder="searchPlaceholder"
        :recommend-list="recommendList"
      />

      <!--  banner菜单 -->
      <BannerNav
        :banner-data="bannerData"
        :menu-nav-list="menuNavData"
        :calendar-list="calendarData"
      />
    </div>

    <div class="main_wrap">
      <!-- 素材快速预览  -->
      <TimelinePic
        id="TimelinePic"
        class="HOME_MAP"
        :pic-data="materialData"
        @showMore="getHomeMaterialListMore"
        @picItemTopicClick="picItemTopicClick"
        @topicMouseover="topicMouseover"
        @picItemClick="picItemClick"
      />

      <!--  推荐专题 -->
      <HomeTopicRecommend
        id="HomeTopicRecommend"
        class="HOME_MAP"
        :topic-data="topicData"
        :topic-data-more="topicDataMore"
        @showMoreTopic="showMoreTopic"
        @picItemTopicClick="picItemTopicClick"
        @topicMouseover="topicMouseover"
        @topicPicClick="topicPicClick"
      />

      <!-- 工作效率  -->
      <WorkEfficiency id="WorkEfficiency" class="HOME_MAP"/>
    </div>

    <no-ssr>
      <HomeHeaderFixed :isShowSearch="false"/>

      <!-- <VipUpgrade/> -->

      <!-- 邀请助力弹框 -->
      <HelpStatusModal
        :isShow="isShow"
        :isShowByFailDialog="isShowByFailDialog"
        :showType="showType"
        @toPay="toPay"
        @close="isShow = false"
        @closeFailModal="isShowByFailDialog = false"
      />

      <ActSlot/>

      <!-- 首页快捷导航   -->
      <HomeMap
        :list="mapList"
        :map-index="mapIndex"
        :compare1400="compare1400"
        @toggleMap="toggleMap"
      />

      <IntroduceCompanyCase class="HOME_MAP IntroduceCompanyCase"/>

      <!-- 桌面收藏bar -->
      <CollectDesktopBar/>

      <!-- 续费通知 -->
      <VipRenewalNotice/>

      <!-- 收藏弹框 -->
      <ModalCollect/>

      <!--        <ActLoginCouponBar/>-->

      <!-- 未登录bar -->
      <NoLoginBar :is-show-mask="false" :is-show-login-bar="false"/>

      <!-- 新用户登录免费下载引导提示弹框 -->
      <!-- <NewUserFreeDownGuideModal/> -->

      <!-- 新用户优惠券 -->
<!--      <ActNewUserCoupon />-->

      <!-- 日下载2次下载完毕弹框 -->
      <downLimitByPersonYearLimitModal />
    </no-ssr>
  </div>
</template>

<script>
import {
  getBanner,
  getDropDownByAll,
  getHomeCalendarList,
  getHomeTopicRecommend,
  getHotwords,
  getMenuNavList,
  homePicPositionClickStat,
} from "@/api/home";
import {
  fnBrowser,
  fnFlowStatistics,
  fnHome,
  fnLayout,
  fnLogin,
  fnRouter,
} from "@/fn";
import _ from "lodash";
import {COMMON} from "@/constant/COMMON";
import {mapState, mapGetters} from "vuex";
import {getHomeMaterialList, getHomeMaterialListMore} from "@/api/material";
import {toolDate} from "@/tools";
import NoLoginBar from "@/components/common/login/no-login-bar";
import {PAGE_PATH} from "@/constant/PAGE_PATH";

import {HOME} from "@/constant/HOME";
import {fnTopic} from "@/fn";
import {TOPIC} from "@/constant/tupian/TOPIC";
import {getWebsiteRatingInvite} from "@/api/common/stat/website-rating";
import {fnBasic} from "@/fn/_fn";
import {checkUserCouponInvite} from "@/api/user";
import {checkInviteByGuoqing} from "@/api/act/guoqing";

/* 页面组件 */
import HomeTopicRecommend from "@/components/home/home-topic-recommend";
import HomeMap from "@/components/home/home-map";
import {PROMOTION_POSITION} from "@/fn/FnFlowStatistics";
import HomeHeader from "@/components/home/home-header/home-header";
import BannerNav from "@/components/home/banner-nav/banner-nav";
import WorkEfficiency from "@/components/home/work-efficiency";
import {
  BackgroundPath,
  TrendDesign,
  CollectDesktopBar,
  TimelinePic,
} from "@/components/home/index.js";

/* 异步加载组件 弹框等 */
// const VipUpgrade = () => import("@/components/home/VipUpgrade");
const ActSlot = () => import("@/components/_Privately/Act/ActSlot");
const NewUserFreeDownGuideModal = () =>
  import("@/components/_components/home/NewUserFreeDownGuideModal");
const IntroduceCompanyCase = () =>
  import("@/components/_components/introduce/company/IntroduceCompanyCase");
const ModalCollect = () => import("@/components/modal/collect");
const VipRenewalNotice = () => import("@/components/common/notice/vip-renewal");
const HomeHeaderFixed = () =>
  import("@/components/home/home-header/home-header-fixed");
const HelpStatusModal = () => import("@/pages/components/HelpStatusModal");
// const ActNewUserCoupon = () => import("@/components/_Privately/Act/ActNewUserCoupon/new-user-coupon.vue");

// import ActGuoQingFloat from "@/components/act/guoqing/ActGuoQingFloat";
// import ActGuoQingBuoy from "@/components/act/guoqing/ActGuoQingBuoy";
// import RabbitHomeBar from '@/components/act/zodiac/rabbit/rabbit-home-bar';
// import ActLoginCouponBar from '@/components/_Privately/Act/ActLoginCouponBar'

import {head} from "./module";
import {PAY_PERSON} from "@/constant/_Public/Path";
const downLimitByPersonYearLimitModal = () => import("@/components/_Privately/Pay/downLimitByPersonYearLimitModal");

export default {
  name: "App",
  layout: "home",
  scrollToTop: true,
  head() {
    return head;
  },
  components: {
    HomeHeader,
    HomeHeaderFixed,
    NoLoginBar,
    HomeTopicRecommend,
    HomeMap,
    NewUserFreeDownGuideModal,
    IntroduceCompanyCase,
    // VipUpgrade,
    ActSlot,
    BackgroundPath,
    TrendDesign,
    CollectDesktopBar,
    TimelinePic,
    BannerNav,
    VipRenewalNotice,
    ModalCollect,
    WorkEfficiency,
    HelpStatusModal,
    // ActNewUserCoupon
    // ActGuoQingFloat,
    // ActGuoQingBuoy,
    // ActLoginCouponBar,
    // RabbitHomeBar,
    downLimitByPersonYearLimitModal
  },
  computed: {
    ...mapState("base", ["isBoot", "header", "spider"]),
    ...mapState("user", ["uniq_id"]),
  },
  data() {
    return {
      recommendList: [],
      searchPlaceholder: "",
      COMMON,
      hotData: {},
      spiderWordsData: {},
      page: 0,
      topicData: [],
      topicDataMore: [],
      HOME,
      mapList: [],
      mapIndex: -1,
      compare1400: true,
      bannerData: {},

      /*picList: [],
      festivalList: [],
      solarTermsList: [],
      recommendList2: [],
      threeDCategoryList: [],
      threeDRecommendList: [],
      festivalData: {},
      styleCategoryList: {},
      styleRecommendList: {},*/

      isShow: false,
      isShowByFailDialog: false,
      showType: 1,

      menuNavData: [],
      calendarData: [],
      materialData: [],
    };
  },
  watch: {
    isLogin: {
      handler(n, o) {
        if (n == true || n == false) {
          if (n == true) {
            setTimeout(() => {
              this.checkShareLink();
              this.checkWebsiteRatingShareLink();
            }, 1500);
          }
        }
      },
      immediate: true,
    },
  },

  mounted() {
    this.init();
  },
  async asyncData({app, store}) {
    let UA = app.context.req.headers["user-agent"];
    let [
      materialData,
      bannerData,
      menuNavData,
      calendarData,
      hotWordsData,
      topicData,
    ] = await Promise.all([
      app.$api.getHomeMaterialList(),
      app.$api.getBanner(),
      app.$api.getMenuNavList(),
      app.$api.getHomeCalendarList(),
      app.$api.getHotwords(),
      app.$api.getHomeTopicRecommend(),
    ]);

    return {
      materialData: fnHome.disposeDayRecommendList(materialData.data),
      bannerData: bannerData.data,
      menuNavData: menuNavData.data,
      isSpider: fnBrowser.isSpider(UA),
      calendarData: calendarData.data,
      recommendList: hotWordsData.data,
      searchPlaceholder: hotWordsData.data.length
        ? hotWordsData.data[0].keyword
        : "",
      topicData: fnHome.disposeTopicList(topicData.data),
    };
  },

  methods: {

    init() {
      this.initFooter();
      this.checkShareLink();
      fnFlowStatistics.pageFlow("home");
      fnFlowStatistics.promotion(PROMOTION_POSITION.BANNER, 0);
      // this.getMenuNavList();
      this.initHomeMapItemScrollTop();
      setTimeout(() => {
        this.scroll();
      }, 500)
      this.checkWebsiteRatingShareLink();

      window.addEventListener("scroll", this.scroll);

      if (process.client) {
        let tempScreenWidth = document.body.clientWidth;
        this.compare1400 = tempScreenWidth > 1440;

        const that = this;
        window.onresize = () => {
          this.initHomeMapItemScrollTop();
          this.scroll();
          return (() => {
            window.screenWidth = document.body.clientWidth;
            that.compare1400 = window.screenWidth > 1440;
          })();
        };
      }

      this.homeInit();
      this.getDropDownByALL();
    },
    homeInit() {
      // this.topicPositionStat();
    },

    getDropDownByALL() {
      getDropDownByAll().then((res) => {
        console.log(res);
        const {status, data} = res.data;
        if (status == 1) {
          this.$store.commit("_Public/Header/setDropDownDataByAll", data);
        }
      });
    },

    checkShareLink() {
      if (fnLogin.checkLogin()) {
        if (this.$route.query && this.$route.query.u2) {
          checkInviteByGuoqing({
            inviter: this.$route.query.u2,
            invitee: this.uniq_id,
          }).then((res) => {
            console.log(res.data.status);
            let {status} = res.data;

            if (status == 200) {
              this.isShow = true;
              this.showType = 2;
              console.log("this.isShowSuccDialog" + this.isShow);
            } else {
              this.isShowByFailDialog = true;
            }
            this.$router.push({
              query: {},
            });
          });
        }
      }

      if (fnLogin.checkLogin()) {
        if (this.$route.query && this.$route.query.u) {
          checkUserCouponInvite({
            inviter: this.$route.query.u,
            invitee: this.uniq_id,
          }).then((res) => {
            console.log(res.data.status);
            let {status} = res.data;

            if (status == 200) {
              this.isShow = true;
              this.showType = 1;
              console.log("this.isShowSuccDialog" + this.isShow);
            } else {
              this.isShowByFailDialog = true;
            }
            this.$router.push({
              query: {},
            });
          });
        }
      }
    },

    initFooter() {
      fnLayout.setFooterBgColor("rgba(245, 246, 250, 1)");
      if (!fnLogin.checkLogin()) {
        document.getElementById("FooterWrap").style.paddingBottom = 100 + "px";
      } else {
        document.getElementById("FooterWrap").style.paddingBottom = 0 + "px";
      }
    },

    initDate() {
      let {month, day, week_zn} = toolDate.getDate(toolDate.getNextDate(0));
      month = toolDate.fillZero(month);
      day = toolDate.fillZero(day);
      return `${month}月${day}日`;
    },

    /* 素材查看更多 */
    getHomeMaterialListMore() {
      let params = {
        page: (this.page += 1),
        pinyin: 0,
        home_keyword_id: 0,
        layout: 0,
        time_filter: 0,
        module: 0,
        scene: 0,
        desc: "new",
      };
      getHomeMaterialListMore(params).then((res) => {
        if (res.data.status == 1) {
          let currentDay = this.initDate();
          let {data} = res.data.list;
          for (const dataKey in data) {
            this.materialData[currentDay].push(data[dataKey]);
          }
          /* 位置重排序 */
          // this.materialData = fnHome.disposeDayRecommendList(this.materialData);
        }
      });
    },
    removeLogin() {
      setTimeout(() => {
        let box = document.getElementById("LoginDialog");
        box.remove();
      }, 2000);
    },
    /* 测试代码 */

    showMoreTopic() {
      // let shiftData = this.topicDataMore.shift();
      // this.topicData = this.topicData.concat(shiftData)
      fnRouter.navigationTo(PAGE_PATH.BASE_URL + PAGE_PATH.TOPIC.DEFAULT);
    },

    initHomeMapItemScrollTop() {
      // if (!this.isSpider) {
      setTimeout(() => {
        /* this.$nextTick(() => {
          const homeMapElements = document.getElementsByClassName("HOME_MAP");
          console.log(homeMapElements.length)
          if (this.HOME.HOME_MAP.length && homeMapElements.length) {
            this.HOME.HOME_MAP.forEach((vo, index) => {
              vo.scrollTop =
                document.getElementsByClassName("HOME_MAP")[index].offsetTop -
                80;
            });
            this.mapList = this.HOME.HOME_MAP;
          }
        }); */
        this.$nextTick(() => {
          const homeMapElements = document.getElementsByClassName("HOME_MAP");
          console.log(homeMapElements.length);
          if (this.HOME.HOME_MAP && this.HOME.HOME_MAP.length && homeMapElements.length) {
            this.HOME.HOME_MAP.forEach((vo, index) => {
              const element = homeMapElements[index];
              if (element) {
                vo.scrollTop = element.offsetTop - 80;
              }
            });
            this.mapList = this.HOME.HOME_MAP;
          }
        });
      }, 500);
      // }
    },

    scroll() {
      this.scrollTop = document.documentElement.scrollTop;
      for (let i = 0; i < this.mapList.length - 1; i++) {
        if (this.scrollTop < this.mapList[0].scrollTop) {
          this.mapIndex = -1;
          return false;
        }

        if (this.scrollTop >= this.mapList[this.mapList.length - 1].scrollTop) {
          this.mapIndex = this.mapList.length - 1;
          return false;
        }

        if (
          this.scrollTop >= this.mapList[i].scrollTop &&
          this.scrollTop <= this.mapList[i + 1].scrollTop
        ) {
          this.mapIndex = i;
        }
      }

      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 300) {
      }
    },

    toggleMap(index) {
      let target = document.getElementsByClassName("HOME_MAP")[index];
      fnBrowser.backToTop(target.offsetTop - 80, 10000000);
      this.mapIndex = index;
    },

    /* 首页专题位置统计 */
    topicPositionStat() {
      let tempMaterialData = this.materialData;
      let tempTopicData = this.topicData;

      let ids = [];
      for (const key in tempMaterialData) {
        if (tempMaterialData[key].length) {
          tempMaterialData[key].forEach((vo) => {
            if (vo.type == 3) {
              ids.push(vo.topic_id);
            }
          });
        }
      }
      if (tempTopicData.length) {
        tempTopicData.forEach((vo) => {
          if (vo.list.length) {
            vo.list.forEach((item) => {
              ids.push(item.id);
            });
          }
        });
      }

      fnTopic.positionStat(
        ids.toString(),
        TOPIC.POSITION_STAT.EVENT.EXPOSE,
        TOPIC.POSITION_STAT.POSITION.HOME
      );
    },

    picItemTopicClick(topicId) {
      fnTopic.positionStat(
        topicId,
        TOPIC.POSITION_STAT.EVENT.CLICK,
        TOPIC.POSITION_STAT.POSITION.HOME
      );
    },

    topicPicClick(topicPic) {
      let {_position, _serial, _type, id} = topicPic;
      homePicPositionClickStat({
        recommend: 2,
        position: _position,
        serial: _serial,
        type: _type,
        location_id: id,
        location_date: "",
      })
        .then((res) => {
        })
        .catch((err) => {
        });
    },

    picItemClick(picItem) {
      let {_position, _serial, id, topic_id, pic_id, type, location_date} =
        picItem;

      let tempId;
      if (type == 1) {
        tempId = pic_id;
      } else if (type == 2) {
        tempId = id;
      } else if (type == 3) {
        tempId = topic_id;
      } else {
        tempId = pic_id;
      }

      if (!_position) {
        console.log("已超过统计数量");
        return;
      }

      homePicPositionClickStat({
        recommend: 1,
        position: _position,
        serial: _serial,
        type: type,
        location_id: tempId,
        location_date: location_date,
      })
        .then((res) => {
        })
        .catch((err) => {
        });
    },

    topicMouseover(topicId) {
      fnTopic.positionStatMouseOver(topicId, TOPIC.POSITION_STAT.POSITION.HOME);
    },

    checkWebsiteRatingShareLink() {
      console.log("---checkWebsiteRatingShareLink");
      if (fnLogin.checkLogin()) {
        if (this.$route.query && this.$route.query.invite) {
          getWebsiteRatingInvite({
            inviter: this.$route.query.invite,
            invitee: this.uniq_id,
          }).then((res) => {
          });
        }
      }
    },

    toPay() {
      this.isShow = false;
      fnBasic.navigationTo(PAY_PERSON);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./home.scss";
</style>
