/* 网站打分 */

import request from "@/utils/request";
import qs from "qs";

/* 获取网站打分状态 */
export function getWebsiteRatingStatus(query) {
  return request({
    url: '?m=Auxfun&a=checkUserWebsiteRating',
    method: 'get',
    params: query
  })
}

/* 网站打分提交 */
export function submitWebsiteRating(data) {
  return request({
    url: '?m=Auxfun&a=websiteRating',
    method: 'post',
    data:qs.stringify(data)
  })
}

/* 获取网站分享和邀请 */
export function getWebsiteRatingInvite(query) {
  return request({
    url: '?m=Auxfun&a=websiteRatingShare',
    method: 'get',
    params: query
  })
}
